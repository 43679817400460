@import "~react-datepicker/dist/react-datepicker.min.css";
@import "./custom/Datepicker.scss";
@import "./custom/Calendar.scss";

@tailwind components;
@tailwind base;
@tailwind utilities;

.ck-content {
  height: 300px;
  overflow: auto;
}

.markdown-editor {
  ul {
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

.bg-media {
  background: repeating-conic-gradient(
      rgb(234 234 234) 0%,
      rgb(216 216 216) 25%,
      transparent 0%,
      transparent 50%
    )
    50% center/20px 20px;
}

.calendar-item {
  &:hover {
    background-color: #2a7871;

    * {
      color: white;
    }
  }
}

.filter-table {
  .react-select__control {
    min-height: 40px;
    padding: 0;
  }

  .react-select__menu {
    right: 0;
    width: 140px;
    text-align: left !important;
  }
}

.billing-table {
  width: 100%;
  th {
    background-color: rgba(#ffbc97, 0.3);
    border: 2px solid #ffffff;

    &:nth-child(1) {
      width: 5%;
    }
    &:nth-child(2) {
      width: 37%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 5%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 23%;
    }
  }

  td {
    height: 32px;
    background-color: rgba(#5ca79f, 0.1);
    border: 2px solid #ffffff;
    padding: 0 16px;
  }

  tr:last-child {
    td {
      border: none;
    }
  }
}

.billing-notes {
  width: 100%;
  margin-top: 8px;

  th {
    &:nth-child(1) {
      width: 77%;
    }
    &:nth-child(2) {
      width: 23%;
    }
  }

  td {
    height: 24px;
    padding: 0 16px;

    &:nth-child(1) {
    }

    &:nth-child(2) {
      text-align: right;
    }
  }
}

.print .billing-notes {
  th {
    &:nth-child(1) {
      width: 81%;
    }
    &:nth-child(2) {
      width: 19%;
    }
  }
}

@media print {
  @page {
    size: A5 landscape;
    margin: 24px;
  }
  body {
    scale: 0.95;
    font-size: 12px !important;

    .text-12 {
      font-size: 10px !important;
    }
  }
}

.ck-sticky-panel__content {
  background: white !important;
  padding: 16px !important;
  border-radius: 8px 8px 0 0 !important;
}

.ck-toolbar {
  border: none !important;
  background: #f5f7fa !important;
  border-radius: 8px !important;
}

.ck-content {
  border-radius: 0 0 8px 8px !important;
  border: none !important;
  padding: 0 16px !important;
  height: 400px !important;

  ol {
    list-style-position: inside;
    list-style-type: decimal;
  }
  ul {
    list-style-position: inside;
    list-style-type: disc;
  }

  h2 {
    font-size: 32px;
  }
  h3 {
    font-size: 24px;
  }
  h4 {
    font-size: 18px;
  }
}

.faq {
  .ck-content {
    height: 100px !important;
  }
}

.rc-tooltip-placement-left {
  .rc-tooltip-arrow {
    right: 4px !important;
  }
}

.animation-press {
  animation: press 0.1s linear;
}

@keyframes press {
  from {
    padding-bottom: 16px;
  }
  to {
    padding-bottom: 5px;
  }
}

.blue-gradient {
  background: linear-gradient(90deg, #5bcbf5 1.94%, #00aeef 25.02%, #0071bc 55.48%, #005aaa 94.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.community_examiner_filter {
  label {
    opacity: 0.3;
    margin-bottom: 0;
    font-size: 14px;
  }

  .react-select__control {
    padding-left: 0;
    min-height: 24px;
  }

  .react-select__value-container {
    padding-left: 0;
    font-size: 16px;
  }
}
